export const bannerConfigs = {

    'm3-001' : [{
        cmsImageUrlWeb : '/static/images/m3-001/banner_0.png',
        cmsImageUrlMobile:'/static/images/m3-001/banner.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm3-002' : [{
        cmsImageUrlWeb : '/static/images/m3-002/QRP_Hotgirl_left.png',
        cmsImageUrlMobile:'/static/images/m3-002/QRP_Hotgirl_MB.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm3-003' : [{
        cmsImageUrlWeb : '/static/images/m3-003/Casino_Desktop.jpg',
        cmsImageUrlMobile:'/static/images/m3-003/Casino_Mobile.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm3-004' : [{
        cmsImageUrlWeb : '/static/images/m3-004/69.jpg',
        cmsImageUrlMobile:'/static/images/m3-004/68.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm3-005' : [{
        cmsImageUrlWeb : '/static/images/m3-005/67.jpg',
        cmsImageUrlMobile:'/static/images/m3-005/66.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],

    'm3-006' : [{
        cmsImageUrlWeb : '/static/images/m3-006/Lottery_Desktop.jpg',
        cmsImageUrlMobile:'/static/images/m3-006/Lottery_Mobiel.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-007' : [{
        cmsImageUrlWeb : '/static/images/m3-007/Desktop.png',
        cmsImageUrlMobile:'/static/images/m3-007/Mobile.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-008' : [{
        cmsImageUrlWeb : '/static/images/m3-008/SlotsPic.jpg',
        cmsImageUrlMobile:'/static/images/m3-008/SlotsPicMobile.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-009' : [{
        cmsImageUrlWeb : '/static/images/m3-009/Casillas_PC_Right.png',
        cmsImageUrlMobile:'/static/images/m3-009/Casillas_Mobile.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-010' : [{
        cmsImageUrlWeb : '/static/images/m3-010/73.png',
        cmsImageUrlMobile:'/static/images/m3-010/72.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-011' : [{
        cmsImageUrlWeb : '/static/images/m3-011/Casillas_PC_Left.png',
        cmsImageUrlMobile:'/static/images/m3-011/Casillas_Mobile_0.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-012' : [{
        cmsImageUrlWeb : '/static/images/m3-011/Casillas_PC_Left.png',
        cmsImageUrlMobile:'/static/images/m3-011/Casillas_Mobile_0.png',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-013' : [{
        cmsImageUrlWeb : '/static/images/m3-013/Desktop_1930x930px_0.jpg',
        cmsImageUrlMobile:'/static/images/m3-013/Mobile_1930x930px_2_1.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }],
    'm3-014' : [{
        cmsImageUrlWeb : '/static/images/m3-014/desktop_angel_1930x930_0.jpg',
        cmsImageUrlMobile:'/static/images/m3-014/Mobile_1930x930px_angel_0.jpg',
        cmsImageUrlMobileAlt : 'Mobile Alt Default',
        cmsImageUrlWebAlt : 'WEB Alt Default'
    }]
};